/* eslint-disable max-lines-per-function */
import { extendSx } from '@deltasierra/react/theme';
import Image from 'next/image';
import Link from 'next/link';
import { Box, Container } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { ReactNode } from 'react';
import NavigationBar from './NavigationBar';
import NavigationButton from './NavigationButton';
import NavigationListMain, { NavigationListMainProps } from './NavigationListMain';
import NavigationListAccount, { NavigationListAccountProps } from './NavigationListAccount';

export const customBreakpoints = {
    // Keys alphabetically ordered to appease sort-keys rule
    '0-sm': 768,
    '1-md': 992,
    '2-lg': 1200,
};

export const sharedValues = {
    gutter: '15px',
    minHeight: '68px',
    transitionDuration: '0.5s',
};

export type NavigationProps = NavigationListAccountProps &
    NavigationListMainProps & {
        children?: ReactNode;
        homeHref: string;
        logoImageSrc: string;
        openDropdownMobile: boolean;
        sx?: SxProps<Theme>;
        toggleOpenDropdownMobile: () => void;
    };

export const Navigation = ({
    adminHref,
    agenciesHref,
    agencyHref,
    brandHref,
    buildAndScheduleHref,
    children,
    clientLinks,
    connectHref,
    homeHref,
    icons,
    learnHref,
    locationDetailsHref,
    logoImageSrc,
    notificationsHref,
    openDropdownMobile,
    openDropdownUser,
    overviewHref,
    planHref,
    profileHref,
    reportHref,
    showAdmin,
    showAgencies,
    showAgency,
    showBrand,
    showBuildAndSchedule,
    showLearn,
    showPlan,
    showReport,
    showSpecialRequests,
    signOutHref,
    specialRequestsHref,
    sx,
    t,
    toggleOpenDropdownMobile,
    toggleOpenDropdownUser,
    userDropdownRef,
    userImageSrc,
    userInitials,
    userName,
    ...props
}: NavigationProps): JSX.Element => (
    <>
        <Box
            {...props}
            aria-label="main navigation"
            role="navigation"
            sx={extendSx(
                {
                    backgroundColor: 'white',
                    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
                    color: 'black',
                    display: 'flex',
                    fontSize: '16px',
                    fontStretch: 'normal',
                    fontWeight: '400',
                    justifyContent: 'center',
                    left: 0,
                    letterSpacing: 'normal',
                    lineHeight: '1.25',
                    paddingTop: 'env(safe-area-inset-top)',
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    whiteSpace: 'nowrap',
                    zIndex: 'drawer',
                },
                sx,
            )}
        >
            <Container
                sx={(theme: Theme) => ({
                    alignItems: 'center',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '20px',
                    justifyContent: 'space-between',
                    minHeight: sharedValues.minHeight,
                    position: 'relative',
                    [theme.breakpoints.up(customBreakpoints['1-md'])]: {
                        flexWrap: 'nowrap',
                        justifyContent: 'flex-start',
                    },
                    width: '100%',
                })}
            >
                <Box
                    component={Link}
                    href={homeHref}
                    sx={{
                        marginLeft: `-${sharedValues.gutter}`,
                        marginTop: '2px',
                        paddingBottom: '11px',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        paddingTop: '15px',
                    }}
                >
                    <Image
                        alt="Digital Stack"
                        height={41}
                        priority
                        src={logoImageSrc}
                        style={{
                            maxHeight: 30,
                            width: 'auto',
                        }}
                        width={300}
                    />
                </Box>
                <NavigationButton
                    sx={(theme: Theme) => ({
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        marginBottom: '8px',
                        marginTop: '11px',
                        minHeight: '34px',
                        [theme.breakpoints.up(customBreakpoints['1-md'])]: {
                            display: 'none',
                        },
                        width: 'auto',
                    })}
                    onClick={toggleOpenDropdownMobile}
                >
                    <span style={visuallyHidden}>{t('Toggle navigation')}</span>
                    <NavigationBar />
                    <NavigationBar />
                </NavigationButton>
                <Box
                    component="nav"
                    sx={[
                        (theme: Theme) => ({
                            backgroundColor: 'white',
                            display: 'grid',
                            gridTemplateColumns: '1fr',
                            gridTemplateRows: '0fr',
                            justifyContent: 'space-between',
                            marginLeft: `-${sharedValues.gutter}`,
                            marginRight: `-${sharedValues.gutter}`,
                            position: 'absolute',
                            [theme.breakpoints.up(customBreakpoints['1-md'])]: {
                                boxShadow: 'none',
                                display: 'flex',
                                marginLeft: 0,
                                marginRight: 0,
                                position: 'relative',
                                top: 0,
                            },
                            top: sharedValues.minHeight,
                            transition: `grid-template-rows ${sharedValues.transitionDuration} ease-out`,
                            width: '100%',
                        }),
                        openDropdownMobile && {
                            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
                            gridTemplateRows: '1fr',
                        },
                    ]}
                >
                    <Box
                        sx={[
                            (theme: Theme) => ({
                                borderTopColor: 'transparent',
                                borderTopStyle: 'solid',
                                borderTopWidth: 1,
                                display: 'flex',
                                overflowX: 'visible',
                                overflowY: 'auto',
                                [theme.breakpoints.up(customBreakpoints['0-sm'])]: {
                                    marginBottom: 0,
                                    marginLeft: sharedValues.gutter,
                                    marginRight: sharedValues.gutter,
                                    marginTop: 0,
                                    width: `calc(100% - ${sharedValues.gutter} - ${sharedValues.gutter})`,
                                },
                                [theme.breakpoints.up(customBreakpoints['1-md'])]: {
                                    marginLeft: 0,
                                    marginRight: 0,
                                    overflowY: 'unset',
                                    width: '100%',
                                },
                                transition: `border-top-color ${sharedValues.transitionDuration} ease-out`,
                                width: '100%',
                            }),
                            openDropdownMobile &&
                                ((theme: Theme) => ({
                                    borderTopColor: 'black',
                                    [theme.breakpoints.up(customBreakpoints['1-md'])]: {
                                        borderTopColor: 'transparent',
                                        transition: 'none',
                                    },
                                })),
                        ]}
                    >
                        <Box
                            sx={[
                                (theme: Theme) => ({
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '300px',
                                    [theme.breakpoints.up(customBreakpoints['1-md'])]: {
                                        flexDirection: 'row',
                                        height: 'unset',
                                    },
                                    width: '100%',
                                }),
                                openDropdownUser &&
                                    ((theme: Theme) => ({
                                        [theme.breakpoints.down(customBreakpoints['1-md'])]: {
                                            height: '320px',
                                        },
                                    })),
                            ]}
                        >
                            <NavigationListMain
                                brandHref={brandHref}
                                buildAndScheduleHref={buildAndScheduleHref}
                                learnHref={learnHref}
                                planHref={planHref}
                                reportHref={reportHref}
                                showBrand={showBrand}
                                showBuildAndSchedule={showBuildAndSchedule}
                                showLearn={showLearn}
                                showPlan={showPlan}
                                showReport={showReport}
                                showSpecialRequests={showSpecialRequests}
                                specialRequestsHref={specialRequestsHref}
                                t={t}
                            />
                            {/* The below is a filler component so the rest of the nav is aligned right */}
                            <div style={{ flex: 'auto' }} />
                            {children}
                            <NavigationListAccount
                                adminHref={adminHref}
                                agenciesHref={agenciesHref}
                                agencyHref={agencyHref}
                                clientLinks={clientLinks}
                                connectHref={connectHref}
                                homeHref={homeHref}
                                icons={icons}
                                locationDetailsHref={locationDetailsHref}
                                notificationsHref={notificationsHref}
                                openDropdownUser={openDropdownUser}
                                overviewHref={overviewHref}
                                profileHref={profileHref}
                                showAdmin={showAdmin}
                                showAgencies={showAgencies}
                                showAgency={showAgency}
                                showPlan={showPlan}
                                signOutHref={signOutHref}
                                t={t}
                                toggleOpenDropdownUser={toggleOpenDropdownUser}
                                userDropdownRef={userDropdownRef}
                                userImageSrc={userImageSrc}
                                userInitials={userInitials}
                                userName={userName}
                            />
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
        <Box sx={{ paddingTop: sharedValues.minHeight }} />
    </>
);

Navigation.displayName = 'Navigation';
