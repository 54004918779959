import { extendSx } from '@deltasierra/react/theme';
import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { ElementType, ReactNode } from 'react';

type NavigationButtonProps = {
    component?: ElementType;
    children: ReactNode;
    href?: string;
    onClick?: () => void;
    onMouseDown?: () => void;
    title?: string;
    sx?: SxProps<Theme>;
};

export default function NavigationButton({ component = 'button', sx, ...props }: NavigationButtonProps): JSX.Element {
    return (
        <Box
            {...props}
            component={component}
            sx={extendSx(
                {
                    '&:hover': {
                        cursor: 'pointer',
                    },
                    alignItems: 'center',
                    backgroundColor: 'white',
                    backgroundImage: 'none',
                    border: '1px solid transparent',
                    borderRadius: '4px',
                    color: 'inherit',
                    display: 'flex',
                    fontFamily: 'inherit',
                    fontSize: 'inherit',
                    gap: '5px',
                    paddingBottom: '9px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    paddingTop: '9px',
                    position: 'relative',
                    width: '100%',
                },
                sx,
            )}
        />
    );
}

NavigationButton.displayName = 'NavigationButton';
