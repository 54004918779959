import { extendSx } from '@deltasierra/react/theme';
import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { forwardRef, ReactNode } from 'react';
import { customBreakpoints } from './Navigation';

type NavigationListProps = {
    children: ReactNode;
    sx?: SxProps<Theme>;
};

const NavigationList = forwardRef(
    ({ sx, ...props }: NavigationListProps, ref): JSX.Element => (
        <Box
            {...props}
            component="ul"
            ref={ref}
            sx={extendSx(
                (theme: Theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    listStyle: 'none',
                    marginBottom: 0,
                    marginLeft: 0,
                    marginRight: 0,
                    marginTop: 0,
                    paddingBottom: '1px',
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 0,
                    [theme.breakpoints.up(customBreakpoints['1-md'])]: {
                        flexDirection: 'row',
                    },
                }),
                sx,
            )}
        />
    ),
);

NavigationList.displayName = 'NavigationList';

export default NavigationList;
