import { extendSx } from '@deltasierra/react/theme';
import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { usePathname } from 'next/navigation';
import { PropsWithChildren } from 'react';
import Link, { LinkProps } from 'next/link';
import { customBreakpoints } from './Navigation';

type NavigationActiveLinkProps = LinkProps & {
    sx?: SxProps<Theme>;
};

export default function NavigationActiveLink({
    sx,
    ...props
}: PropsWithChildren<NavigationActiveLinkProps>): JSX.Element {
    const pathname = usePathname();
    return (
        <Box
            {...props}
            component={Link}
            data-active={pathname === props.href}
            sx={extendSx(
                (theme: Theme) => ({
                    color: 'inherit',
                    display: 'flex',
                    paddingBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    paddingTop: '10px',
                    textDecoration: 'none',
                    [theme.breakpoints.up(customBreakpoints['2-lg'])]: {
                        paddingLeft: '15px',
                        paddingRight: '15px',
                    },
                    [theme.breakpoints.up(customBreakpoints['1-md'])]: {
                        paddingBottom: '15px',
                        paddingTop: '15px',
                    },
                }),
                sx,
            )}
        />
    );
}

NavigationActiveLink.displayName = 'NavigationActiveLink';
