import { extendSx } from '@deltasierra/react/theme';
import { Translations } from '@deltasierra/translations/react';
import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { customBreakpoints } from './Navigation';
import NavigationActiveLink from './NavigationActiveLink';
import NavigationList from './NavigationList';
import NavigationListItem from './NavigationListItem';
import NavigationUnderline from './NavigationUnderline';

export type NavigationListMainProps = {
    brandHref: string;
    buildAndScheduleHref: string;
    learnHref: string;
    planHref: string;
    reportHref: string;
    showBrand: boolean;
    showBuildAndSchedule: boolean;
    showLearn: boolean;
    showPlan: boolean;
    showReport: boolean;
    showSpecialRequests: boolean;
    specialRequestsHref: string;
    sx?: SxProps<Theme>;
    t: Translations<'Navigation'>;
};

export default function NavigationListMain({
    brandHref,
    buildAndScheduleHref,
    learnHref,
    planHref,
    reportHref,
    showBrand,
    showBuildAndSchedule,
    showLearn,
    showPlan,
    showReport,
    showSpecialRequests,
    specialRequestsHref,
    sx,
    t,
    ...props
}: NavigationListMainProps): JSX.Element {
    return (
        <NavigationList
            {...props}
            sx={extendSx(
                (theme: Theme) => ({
                    [theme.breakpoints.down(customBreakpoints['1-md'])]: {
                        marginTop: '5px',
                    },
                }),
                sx,
            )}
        >
            {showLearn && (
                <NavigationListItem>
                    <NavigationActiveLink href={learnHref}>
                        <NavigationUnderline>{t('Learn')}</NavigationUnderline>
                    </NavigationActiveLink>
                </NavigationListItem>
            )}
            {showPlan && (
                <NavigationListItem>
                    <NavigationActiveLink href={planHref}>
                        <NavigationUnderline>{t('Plan')}</NavigationUnderline>
                    </NavigationActiveLink>
                </NavigationListItem>
            )}
            {showBuildAndSchedule && (
                <NavigationListItem>
                    <NavigationActiveLink href={buildAndScheduleHref}>
                        <NavigationUnderline>{t('Build & Schedule')}</NavigationUnderline>
                    </NavigationActiveLink>
                </NavigationListItem>
            )}
            {showReport && (
                <NavigationListItem>
                    <NavigationActiveLink href={reportHref}>
                        <NavigationUnderline>{t('Report')}</NavigationUnderline>
                    </NavigationActiveLink>
                </NavigationListItem>
            )}
            {showSpecialRequests && (
                <NavigationListItem>
                    <NavigationActiveLink href={specialRequestsHref}>
                        <NavigationUnderline>{t('Special Requests')}</NavigationUnderline>
                    </NavigationActiveLink>
                </NavigationListItem>
            )}
            {showBrand && (
                <NavigationListItem>
                    <NavigationActiveLink href={brandHref}>
                        <NavigationUnderline>{t('Brand')}</NavigationUnderline>
                        <Box
                            component="span"
                            sx={{
                                backgroundColor: 'grey.600',
                                borderRadius: '10px',
                                color: 'white',
                                display: 'inline-block',
                                fontSize: '10px',
                                fontWeight: '400',
                                height: '16px',
                                lineHeight: '1',
                                minWidth: '10px',
                                paddingBottom: '3px',
                                paddingLeft: '7px',
                                paddingRight: '7px',
                                paddingTop: '3px',
                                position: 'relative',
                                textAlign: 'center',
                                top: '-6px',
                                verticalAlign: 'baseline',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {t('Beta')}
                        </Box>
                    </NavigationActiveLink>
                </NavigationListItem>
            )}
        </NavigationList>
    );
}

NavigationListMain.displayName = 'NavigationListMain';
